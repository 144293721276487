<template>
    <div>
        <div v-if="'text' in columnData" v-html="columnData.text"/>
        <a v-if="!'hide_empty' in columnData || !columnData.hide_empty || !!columnData.caption"
           @click="emitAction" class="link">
            {{columnData.caption}}
        </a>
    </div>
</template>

<script>
export default {
    props: {
        rowId: Number,
        columnData: Object,
    },

    methods: {
        emitAction() {
            let params = {action: this.columnData.action, rowId: this.rowId};

            if ('params' in this.columnData) {
                params.params = this.columnData.params;
            }

            this.$emit('action', params);
        }
    }
}
</script>

<style scoped>

</style>
